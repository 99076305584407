*{
    padding: 0;
    margin: 0;
    
}

@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');

body{
    font-family: 'Raleway', sans-serif;
    background-color: #09090B;
    color:white;
}
.recomendados{
    /* position: absolute;
    right: 0;
    text-align: right;
    top:0; */
}
.top{
    margin-top:1rem;
    margin-bottom: 1rem;
    text-align: center;
}

.top input, .top .buscar, .caja, .recomendado,.top .nav, .recomendados button{
    font-family: 'Raleway', sans-serif;
    font-size: 2rem;
    padding: 0.6rem;
    background-color: rgba(0, 0, 0, 0);
    color: #a1a1aa;
    border-radius: 15px;
    border: none;
}

/* .top input, .top .buscar, .caja, .recomendado,.top .nav, .recomendados button{
    font-family: 'Raleway', sans-serif;
    font-size: 2rem;
    padding: 0.6rem;
    outline: none;
    background-color: rgba(0, 0, 0, 0);
    color: white;
    border: solid 1px white;
    transition: 0.1s ;
    
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    outline: 0px;
    border: 0px;
    margin: 0px 8px;
    border-radius: 0px;
    cursor: pointer;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    text-transform: none;
    user-select: none;
    font-family: Manrope, sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.25;
    max-width: 360px;
    position: relative;
    flex-shrink: 0;
    overflow: hidden;
    white-space: normal;
    text-align: center;
    flex-direction: column;
    padding: 8px 0px;
    min-width: 0px;
    color: rgb(161, 161, 170);
    height: 36px;
    min-height: 36px;
} */
 
.recomendados button{
    font-size: 1rem;
    width: 4rem;
}
.top button{
    cursor: pointer;
}
/* .top button:hover{
    background-color: rgba(255,255,255,0.3);
    
} */
.top button:focus{
    /*outline: 1px white solid;*/
    /* background-color: rgba(255,255,255,0.3); */
}
button.seleccion{
    background-color: rgba(255,255,255,0.3);
    color: white !important;
    border-radius: 15px;
}

.top input{
    width:100%;
    border: none;
    padding-left: 3rem;
    position: absolute;
    left: 0;
    box-sizing:border-box;
    padding-right: 4rem;
}
.top .buscar{
    height:  3.4rem;
    width: 3.4rem;
    line-height: 0;
    padding: 0;
    position: absolute;
    right: 0;
    bottom: 0;
    border: none;
    border-left: solid 1px white;
    cursor: pointer;
}
.top button img{
    cursor: pointer;
    height: 2rem;
    
}
.top .prefijo{
    left: 0;
    position: absolute;
    line-height: 3.4rem;
    margin-left: 0.5rem;
    color: white;
    user-select: none;
    z-index: 1;
}
.sb {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 90px;
    padding: 1px 20px;
    margin-bottom: 45px;
}

.recomendado {
    margin: 0 10px;
    padding: 5px 10px;
    border: none;
    background: none;
    color: rgb(161, 161, 170);
    font-size: 14px;
    cursor: pointer;
    transition: color 0.3s;
}

.recomendado.active {
    font-weight: bold;
    color: white !important; /* Active button color */
}

.underline {
    position: absolute;
    bottom: -5px;
    height: 2px;
    background: white; /* Underline color */
    transition: left 0.3s ease, width 0.3s ease; /* Smooth animation for movement and size */
}

.estado{
    text-align: center;
}
.caja{
    width: 40%;
    height: 3.4rem;
    position: relative;
    padding: 0;
    margin: auto;
}
.contenedor{
    width: 86%;
    margin: 20px auto;
    columns: 4;
    column-gap: 20px;
}

.postal{
    /*movil
    max-height: 100%;
    height: auto;
    width:auto;*/
    position: relative;
    width:100%;
    margin-top: 10px;
    margin-bottom: 10px;
    break-inside: avoid;
}
.hover:hover ~ h3,.hover:hover ~ a{
    transform: translate(0,0);
    z-index: 1;
    color:rgba(255,255,255,1);
    text-shadow:0 0 2px rgba(0,0,0, 1);
}
.hover{
    height: 100%;
    width: 100%;
    position: absolute;
    cursor: pointer;
    z-index: 2;
}
.postal img{
    max-width: 100%; 
    border-radius: 15px;
}
.postal h3{
    transform: translate(0,-20px);
    z-index: -1;
    text-shadow:0 0 2px rgba(0,0,0, 0);
    position: absolute;
    right: 0;
    top: 0;
    color:rgba(0,0,0,0);
    transition: 0.3s ease;
    font-weight: normal;
    margin: 0.6rem;
}
.postal a{
    transform: translate(0,20px);
    z-index: -1;
    text-shadow:0 0 2px rgba(0,0,0, 0);
    position: absolute;
    right: 0;
    bottom: 0;
    color:rgba(0,0,0,0);
    transition: 0.3s ease;
    font-weight: normal;
    margin: 0.6rem;
}
  
@media(max-width:1200px){
    .contenedor{
        width: calc(100% - 40px);
        columns: 3;
    }
} 
@media(max-width:600px){
    .contenedor{
        columns: 2;
    }
}
.zoom img{
    position: fixed;
    right: 50%;
    bottom:50vh;
    transform: translate(50%,50%);
    max-height: 100vh;
    height: 100vh;
    max-width: 100vw;
    z-index: 2;
    cursor: pointer;
}

.nav{
    height: 3.4rem;
    width: 3.4rem;
    cursor: pointer;
    line-height: 0;
}
footer{
    text-align: right;
    padding: 1rem;
}
footer a{
    color:white;
    
}

@media only screen and (max-width: 600px) {
    .top .caja{
        margin-top: 3rem;
        width: 99vw;
    }
    .postal{
        height: auto;
        width: 100%;
    }
    .postal img{
        max-width: 100%;
        width: 100%;
        height: auto;
    }
    .zoom img{
        position: fixed;
        left:  0;
        bottom:50vh;
        transform: translate(0,50%);
        max-height: 100vh;
        width: 100vw;
    }
    .nav{
        width: 50%;
    }
    .recomendados button{
        height: 2rem;
        line-height: 0;
    }

}
.zoom{
    background-color: rgba(0,0,0, 0.7);
    z-index: 4;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
}
.zoom .hover{
    z-index: 1;
}
.zoom h3 , .zoom a{
    z-index: 6;
    font-size:1.5rem;
    color: white;
    transform: translate(0,0);
    text-shadow:0 0 3px rgba(0,0,0, 1);
}
.zoom a{
    cursor: pointer;
    text-decoration: underline;
}
.my-masonry-grid {
    display: flex;
    margin-left: -30px; /* gutter size offset */
    width: auto;
}

.my-masonry-grid_column {
    padding-left: 30px; /* gutter size */
    background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div { /* change to match your item class */
    margin-bottom: 30px;
}
.top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 40px 40px 60px;
}

.logo img {
    margin-right: 20px;
}



.ad-container1 {
    width: 100%; /* Ensure the iframe takes up the full width of its container */
    padding-bottom: 71%; /* Maintain aspect ratio (300x250 = 75% aspect ratio) */
    position: relative; /* Relative positioning for the iframe inside */
    border-radius: 15px; /* Apply the border radius to the container */
    overflow: hidden;
  }
  
  .ad-container1 iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    border: 0;
  }

  .ad-container {
    width: 100%; /* Ensure the iframe takes up the full width of its container */
    padding-bottom: 87%; /* Maintain aspect ratio (300x250 = 75% aspect ratio) */
    position: relative; /* Relative positioning for the iframe inside */
    border-radius: 15px; /* Apply the border radius to the container */
    overflow: hidden;
  }
  
  .ad-container iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    border: 0;
  }
